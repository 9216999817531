import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import '../components/layout.css'
import { 
  Box
} from "@chakra-ui/react"
import Seo from "../Seo"
import SliceZone from '../components/SliceZone/SliceZone'

import PageHero from "../components/PageHero/PageHero"

const PageTemplate = ({ data }) => {

  const document = data.prismicAltPage.data;

  return (
    <Layout>
      <Seo title="About Us | Plateau Forest Products" />
      <PageHero 
        heroImage={document.hero_image?.localFile}
        alt={document.hero_image.alt}
        pageTitle={document.page_title.text}
        pageSubtitle={document.page_subtitle.text}
      />
        <Box>
          <SliceZone sliceZone={document.body}/>
        </Box>
    </Layout>
  )
}

export const query = graphql`
query AltPageQuery($uid: String!) 
{
  prismicAltPage(uid: {eq: $uid}) {
    uid
    data {
      page_title {
        text
      }
      page_subtitle {
        text
      }
      hero_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 80, placeholder: BLURRED)
          }
        }
        alt
      }
      body {
        ... on PrismicAltPageDataBodyWhiteCallToActionBlock {
          id
          slice_type
          primary {
            yellow_title {
              text
            }
            white_headline {
              text
            }
            button_link {
              uid
              url
            }
          }
        }
        ... on PrismicAltPageDataBodyQuoteBlock {
          id
          slice_type
          primary {
            quote {
              text
            }
          }
        }
        ... on PrismicAltPageDataBodyGreenCallToActionBlock {
          id
          slice_type
          primary {
            yellow_title {
              text
            }
            white_headline {
              text
            }
            button_link {
              url
              uid
            }
            button_label {
              text
            }
          }
        }
        ... on PrismicAltPageDataBodyContentArea {
          id
          slice_type
          primary {
            white_or_green_block_
            content_title {
              text
            }
            content {
              raw
            }
          }
        }
        ... on PrismicAltPageDataBodyCompanyOverviewBlock {
          id
          slice_type
          primary {
            description {
              text
            }
            block_title {
              text
            }
          }
        }
        ... on PrismicAltPageDataBodyLeftColumn {
          id
          slice_type
          primary {
            right_column_content {
              raw
            }
            left_column_content {
              raw
            }
          }
        }
        ... on PrismicAltPageDataBodyPreformattedText {
          id
          slice_type
          primary {
            preformatted {
              raw
              text
            }
          }
        }
      }
    }
  }
}
`


export default PageTemplate